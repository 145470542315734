import React from "react"
import PropTypes from "prop-types"
import NavigationBar from "../components/Navbar"
import Footer from "../components/footer"

// Utilities
import kebabCase from "lodash/kebabCase"

// Components
import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
  },
}) => (
  <div>
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
    >
      <meta charSet="utf-8" />
      <title>Tags</title>
      <meta name="description" content="All Tags Page" />
    </Helmet>
    <div className="text-center">
      <NavigationBar />
      <h1 className="inline-block px-10 py-4 mt-2 text-2xl font-extrabold text-center bg-green-400 rounded-lg">
        All Tags
      </h1>
      <ul className="grid mx-4 mt-10 mb-8 lg:grid-cols-3 lg:mx-20 md:mx-10">
        {group.map(tag => (
          <Link
            to={`/tags/${kebabCase(tag.fieldValue)}/`}
            className="px-6 py-6 mx-8 mt-4 bg-gray-100 border border-black rounded-lg shadow-lg text-nowrap"
            key={tag.fieldValue}
          >
            <div className="text-lg font-bold uppercase">
              {tag.fieldValue} {tag.totalCount}
            </div>
          </Link>
        ))}
      </ul>
    </div>
    <Footer />
  </div>
)

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
  }),
}

export default TagsPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
